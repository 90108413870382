import React from "react";
import styled from "styled-components";
import {
  mainBlack,
  mainBlue,
  transHover,
  mainWhite,
  screen,
} from "../variables/variables";
import FilterDropdown from "./filter";
import SortDropdown from "./sort";
import { GoTriangleDown } from "react-icons/go";

// framer motion config for variants
const container = {
  hidden: { opacity: 0, pointerEvents: "none" },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.07,
    },
    pointerEvents: "all",
  },
};

const item = {
  hidden: { opacity: 0, x: -14, y: 14 },
  show: { opacity: 1, x: 0, y: 0 },
};

const Wrapper = styled.div`
  margin: 0 0 30px 0;
  @media ${screen.xsmall} {
    margin: 0 0 60px 0;
  }

  .filter-row {
    display: flex;
    justify-content: flex-end;

    .filter-col {
      margin: 0 0 0 20px;
      position: relative;
      z-index: 10;
      @media ${screen.xsmall} {
        margin: 0 0 0 30px;
      }
    }

    &__trigger {
      color: ${mainBlack};
      border: 1px ${mainWhite} solid;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding: 7px 14px 7px 16px;
      border-radius: 8px;
      cursor: pointer;
      @media ${screen.xsmall} {
        padding: 7px 16px;
      }

      span {
        font-weight: 500;
        margin: 0 9px 0 0;
        transition: all 0.2s ease-in-out;
        font-size: 0.9rem;
        @media ${screen.xsmall} {
          font-size: 1rem;
        }

        .hide-on-mobile {
          display: none;
          @media ${screen.xsmall} {
            display: inline;
          }
        }
      }

      svg {
        font-size: 1.25rem;
        transition: ${transHover};
      }

      &--active {
        color: ${mainBlue};
        border: 1px ${mainBlue} solid;

        svg {
          transform: rotate(180deg);
        }
      }

      @media ${screen.withCursor} {
        &:hover span {
          color: ${mainBlue};
        }
      }
    }
  }
`;

const InsightsDropdowns = ({
  categories,
  activeCategories,
  activeDropdown,
  activeSort,
  handleDropdownClick,
  handleFilterClick,
  handleSortClick,
  addToQuerySearch,
}) => {
  const getClassName = (val) => {
    if (activeDropdown.includes(val))
      return "filter-row__trigger filter-row__trigger--active";

    return "filter-row__trigger";
  };

  return (
    <Wrapper>
      <div className="filter-row">
        <div className="filter-col">
          <button
            className={getClassName("filter")}
            onClick={() => handleDropdownClick("filter")}
          >
            <span>
              FILTER BY<span className="hide-on-mobile"> CATEGORY</span>
            </span>

            <GoTriangleDown />
          </button>

          <FilterDropdown
            isOpen={activeDropdown.includes("filter")}
            options={categories}
            activeCategories={activeCategories}
            handleFilterClick={handleFilterClick}
            container={container}
            item={item}
            addToQuerySearch={addToQuerySearch}
          />
        </div>
        <div className="filter-col">
          <button
            className={getClassName("sort")}
            onClick={() => handleDropdownClick("sort")}
          >
            <span>SORT BY</span>
            <GoTriangleDown />
          </button>

          <SortDropdown
            isOpen={activeDropdown.includes("sort")}
            activeSort={activeSort}
            handleSortClick={handleSortClick}
            container={container}
            item={item}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default InsightsDropdowns;
