import React from "react";
import styled from "styled-components";
import { mainBlack, screen } from "../variables/variables";
import { motion } from "framer-motion";
import { BsCheck } from "react-icons/bs";

const Wrapper = styled(motion.div)`
  background-color: #f1f0f0;
  box-shadow: 0 3px 14px rgba(30, 30, 30, 0.05);
  padding: 18px;
  position: absolute;
  top: 46px;
  right: 0;
  z-index: 1;
  width: 100%;
  min-width: 136px;
  @media ${screen.xsmall} {
    min-width: 147px;
  }

  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;

    &:last-child {
      margin: 0;
    }

    &__box {
      min-width: 12px;
      min-height: 12px;
      position: relative;
      /* border: 1px ${mainBlack} solid; */
      margin: 0 12px 0 0;

      svg {
        position: absolute;
        top: -7px;
        left: -7px;
        font-size: 1.475rem;
      }
    }

    &__label {
      font-size: 1rem;
      font-weight: 300;
      white-space: pre;
      display: block;
    }
  }
`;

const Sort = ({ isOpen, activeSort, handleSortClick, container, item }) => {
  return (
    <Wrapper
      variants={container}
      initial="hidden"
      animate={isOpen ? "show" : "hidden"}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="option"
        role="button"
        onClick={() => handleSortClick(null)}
        variants={item}
      >
        <span className="option__box">
          {activeSort === null && <BsCheck />}
        </span>
        <span className="option__label">All</span>
      </motion.div>
      <motion.div
        className="option"
        role="button"
        onClick={() => handleSortClick("newest")}
        variants={item}
      >
        <span className="option__box">
          {activeSort === "newest" && <BsCheck />}
        </span>
        <span className="option__label">Newest</span>
      </motion.div>

      <motion.div
        className="option"
        role="button"
        onClick={() => handleSortClick("oldest")}
        variants={item}
      >
        <span className="option__box">
          {activeSort === "oldest" && <BsCheck />}
        </span>
        <span className="option__label">Oldest</span>
      </motion.div>
    </Wrapper>
  );
};

export default Sort;
