import React from "react";
import styled from "styled-components";
import { mainBlack, screen } from "../variables/variables";
import { AnimatePresence, motion } from "framer-motion";
import { toSlug } from "../utils/helpers";

const Wrapper = styled(motion.div)`
  background-color: #f1f0f0;
  box-shadow: 0 3px 14px rgba(30, 30, 30, 0.05);
  padding: 18px;
  position: absolute;
  top: 46px;
  right: 0;
  z-index: 100;
  width: 100%;
  min-width: 190px;
  @media ${screen.xsmall} {
    min-width: none;
  }

  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;

    &:last-child {
      margin: 0;
    }

    &__box {
      min-width: 12px;
      min-height: 12px;
      border: 1px ${mainBlack} solid;
      margin: 0 12px 0 0;

      &--active {
        background-color: ${mainBlack};
      }
    }

    &__label {
      font-size: 1rem;
      font-weight: 300;
      white-space: pre;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const Filter = ({
  isOpen,
  options,
  activeCategories,
  handleFilterClick,
  container,
  item,
}) => {
  const getClassName = (val) => {
    if (activeCategories.includes(val))
      return "option__box option__box--active";

    return "option__box";
  };

  return (
    <Wrapper
      variants={container}
      initial="hidden"
      animate={isOpen ? "show" : "hidden"}
      exit={{ opacity: 0 }}
    >
      {options.map((option) => (
        <motion.div
          className="option"
          role="button"
          onClick={() => handleFilterClick(toSlug(option))}
          variants={item}
          key={option}
        >
          <span className={getClassName(toSlug(option))}></span>
          <span className="option__label">{option}</span>
        </motion.div>
      ))}
    </Wrapper>
  );
};

export default Filter;
