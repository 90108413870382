import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../css/global.css";
import { toSlug } from "../components/utils/helpers";
import formatDate from "../components/utils/format-date";
import InsightsDropdowns from "../components/insights/dropdowns";
import styled from "styled-components";
import { motion } from "framer-motion";
import Banner from "../components/banner/banner";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import CtaGradient from "../components/cta-gradient/cta-gradient";
import CardList from "../components/card-list/card-list";
import qs from "qs";
import {
  mainBlack,
  mainWhite,
  screen,
  whileInVew,
} from "../components/variables/variables";

const Wrapper = styled.div`
  position: relative;
  z-index: 2;

  .main {
    background: ${mainWhite};
    color: ${mainBlack};
    padding: 45px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }
  }

  .cta {
    padding: 0 0 45px 0;
    @media ${screen.xsmall} {
      padding: 0 0 90px 0;
    }
  }
`;

// turn date to number, to be used for sorting
const numericDate = (str) => {
  // expected value format
  // "2022-06-28";
  // "2022-06-28T23:46:48+0000";
  if (!str) return "";

  const trimmed = str.slice(0, 10);

  return parseFloat(trimmed.replace(/-/g, ""));
};

// format article based on prismic data
const formatArticles = (arr, order) => {
  const formatted = arr.map((article) => {
    const { node } = article;

    return {
      id: node.id,
      title: node.data.title.text,
      description: node.data.text_preview.html,

      image: node.data.featured_image.thumbnails.cropped.gatsbyImageData,
      alt: node.data.featured_image.alt,
      date: node.data.edit_published_date
        ? formatDate(node.data.edit_published_date)
        : formatDate(node.first_publication_date),
      dateAsNumber: node.data.edit_published_date
        ? numericDate(node.data.edit_published_date)
        : numericDate(node.first_publication_date),
      categories: node.data.categories.map((category) => {
        return toSlug(category.category.document?.data.title.text);
      }),
    };
  });

  // default to ASC
  if (order === "desc") {
    return formatted.sort((a, b) => a.dateAsNumber - b.dateAsNumber);
  }

  return formatted.sort((a, b) => b.dateAsNumber - a.dateAsNumber);
};

// search param url handler
// return e.g category=technology,health
const searchUrl =
  typeof window !== "undefined" && window.location.search.slice(1);

// return e.g { category: "technology,health" }
const parsedSearchUrl = qs.parse(searchUrl);

// retrun e.g ["technology", "health"]
const categoriesFromUrl = parsedSearchUrl?.category?.split(",");

// retrun e.g "oldest"
const sortByFromUrl = parsedSearchUrl.sort;

// transform object to string, to be used for updating search param url
const stringifySelection = ({ categories, sort }) => {
  if (!sort && !categories.length) return "?";

  const stringifyCategories = !!categories.length
    ? `category=${categories.join(",")}`
    : "";

  const stringifySort = !!categories.length ? `&sort=${sort}` : `sort=${sort}`;

  return `?${stringifyCategories}${sort ? stringifySort : ""}`;
};
// end handler

const InsightsPage = ({ data }) => {
  const {
    banner_background_image,
    banner_background_image_mobile,
    banner_title,
    banner_description,
    cta_heading,
    cta_description,
    cta_button_label,
    title_tag,
    meta_description,
  } = data.content.data;

  const { edges: allCategories } = data.categories;

  const { edges: allArticles } = data.articles;

  const [activeDropdown, setActiveDropdown] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [activeSort, setActiveSort] = useState(null);

  useEffect(() => {
    if (categoriesFromUrl) setActiveCategories(categoriesFromUrl);

    if (sortByFromUrl) setActiveSort(sortByFromUrl);
  }, []);

  const getArticles = () => {
    const order = activeSort === "oldest" ? "desc" : "asc";

    if (activeCategories.length === 0) {
      return formatArticles(allArticles);
    }

    return formatArticles(allArticles, order);
  };

  const articles = useMemo(
    () => getArticles(),

    // eslint-disable-next-line
    [activeCategories, activeSort]
  );

  const handleFilterByCategory = () => {
    let filteredArticles;

    if (activeCategories.length === 0) {
      filteredArticles = articles;
    } else {
      filteredArticles = articles.filter((article) => {
        return article.categories.some((category) =>
          activeCategories.includes(category)
        );
      });
    }

    if (activeSort === "oldest") {
      return filteredArticles.sort((a, b) => a.dateAsNumber - b.dateAsNumber);
    }

    return filteredArticles;
  };

  // filtered articles to render
  const renderArticles = useMemo(
    () => handleFilterByCategory(articles),

    // eslint-disable-next-line
    [activeCategories, activeSort]
  );

  const categories = allCategories.map(
    (category) => category.node.data.title.text
  );

  // current active dropdown
  const handleDropdownClick = (val) => {
    if (activeDropdown.includes(toSlug(val))) {
      const filtered = activeDropdown.filter((active) => active !== val);

      setActiveDropdown(filtered);

      return;
    }

    setActiveDropdown([val, ...activeDropdown]);
  };

  // filter handler on click
  const handleFilterClick = (val) => {
    const filtered = activeDropdown.filter((val) => "filter" !== val);
    setActiveDropdown(filtered);
    if (activeCategories.includes(val)) {
      const filtered = activeCategories.filter((active) => active !== val);

      setActiveCategories(filtered);

      return;
    }

    setActiveCategories([val, ...activeCategories]);
  };

  // sort handler on click
  const handleSortClick = (val) => {
    const filtered = activeDropdown.filter((val) => "sort" !== val);
    setActiveDropdown(filtered);

    setActiveSort(val);
  };

  const updateSearchParamUrl = () => {
    window.history.pushState(
      {},
      "",
      stringifySelection({
        categories: activeCategories,
        sort: activeSort,
      })
    );
  };

  // add in search param url on every update
  useEffect(() => {
    updateSearchParamUrl();
  }, [activeCategories, activeSort]);

  return (
    <Layout>
      <Seo
        title={title_tag}
        description={meta_description}
        image={banner_background_image.thumbnails.og.url}
      />
      <Wrapper>
        <Banner
          banner_background_image={banner_background_image}
          banner_background_image_mobile={banner_background_image_mobile}
          banner_title={banner_title}
          banner_description={banner_description}
          textWidth={700}
        />

        <motion.section className="main" {...whileInVew}>
          <InnerWrapper>
            <InsightsDropdowns
              categories={categories}
              activeDropdown={activeDropdown}
              activeCategories={activeCategories}
              activeSort={activeSort}
              handleDropdownClick={handleDropdownClick}
              handleSortClick={handleSortClick}
              handleFilterClick={handleFilterClick}
            />
            <CardList isArticles items={renderArticles} />
          </InnerWrapper>
        </motion.section>

        <section className="cta">
          <CtaGradient
            cta_heading={cta_heading}
            cta_description={cta_description}
            cta_button_label={cta_button_label}
            relative
          />
        </section>
      </Wrapper>
    </Layout>
  );
};

export default InsightsPage;

export const dataQuery = graphql`
  {
    content: prismicInsightsPage {
      data {
        banner_background_image_mobile {
          url
        }
        banner_background_image {
          url
          thumbnails {
            og {
              url
            }
          }
        }
        banner_title {
          text
        }
        cta_heading {
          text
        }
        cta_description {
          html
        }
        cta_button_label
        title_tag
        meta_description
      }
    }
    categories: allPrismicCategory(
      sort: { fields: data___title___text, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }

    articles: allPrismicInsight {
      edges {
        node {
          id
          first_publication_date
          data {
            edit_published_date
            title {
              text
            }
            meta_description
            text_preview {
              html
            }
            featured_image {
              alt
              thumbnails {
                cropped {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            categories {
              category {
                id
                document {
                  ... on PrismicCategory {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
